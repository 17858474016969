import { Controller } from "@hotwired/stimulus"
import Mark from "mark.js"
import { isMobile } from "../../../ui/static_src/ui/utils/breakpoints"

const HIGHLIGHTED_CLASS = "-highlighted"
const DISABLED_CLASS = "-disabled"
const SCROLLING_INTO_CLASS = "-scrolling-into"

class TranscriptVideo extends Controller {
  static targets = ["chapter", "emptyText", "paragraph", "speaker", "video"]
  static attributeStartTime = "data-transcript-video-start-timecode-value"
  static attributeEndTime = "data-transcript-video-end-timecode-value"
  static classActive = "-active"
  static classHighlighted = "-highlighted"
  static classParagraph = ".transcript-paragraph__text"

  navigateToContent() {
    if (!this.videoTarget.paused) {
      const currentTime = this.videoTarget.currentTime
      // Get paragraph
      this.selectParagraph(currentTime)
      // Get chapter
      this.selectChapter(currentTime)
    }
  }

  selectParagraph(timecode) {
    const targetElement = this.getTargetElement(this.paragraphTargets, timecode)
    const currentElement = this.getCurrentElement(this.paragraphTargets, TranscriptVideo.classActive)

    if (targetElement) {
      this.format(currentElement, targetElement)
      // remove format
      if (currentElement) {
        const currentSpeaker = this.getSpeaker(currentElement)
        currentSpeaker.classList.remove(TranscriptVideo.classActive)
      }
      // apply format
      const speaker = this.getSpeaker(targetElement)
      speaker.classList.add(TranscriptVideo.classActive)
    }
  }

  selectChapter(timecode) {
    const targetElement = this.getTargetElement(this.chapterTargets, timecode)
    const currentElement = this.getCurrentElement(this.chapterTargets, TranscriptVideo.classActive)

    if (targetElement) {
      this.format(currentElement, targetElement)
    }
  }

  getTargetElement(targets, timecode) {
    return targets.find((element) => parseFloat(element.getAttribute(TranscriptVideo.attributeStartTime)) <= timecode && parseFloat(element.getAttribute(TranscriptVideo.attributeEndTime)) > timecode)
  }

  getCurrentElement(targets, cssClass) {
    return targets.find((element) => element.classList.contains(cssClass))
  }

  format(currentElement, targetElement) {
    if (currentElement && currentElement.getAttribute(TranscriptVideo.attributeStartTime) !== targetElement.getAttribute(TranscriptVideo.attributeStartTime)) {
      currentElement.classList.remove(TranscriptVideo.classActive)
    }
    targetElement.classList.add(TranscriptVideo.classActive)
  }

  getSpeaker(element) {
    let speaker
    let sibling = element.previousElementSibling
    while (sibling) {
      if (sibling.matches("span.transcript-paragraph__speaker")) {
        speaker = sibling
        return speaker
      }
      sibling = sibling.previousElementSibling
    }
  }

  play(e) {
    const timecode = e.target.value || e.currentTarget.getAttribute(TranscriptVideo.attributeStartTime)
    this.videoTarget.currentTime = parseFloat(timecode.replace(",", "."))
    this.videoTarget.play()

    if (!isMobile()) {
      this.scrollToElement(e.currentTarget)
    }
  }

  scrollToElement(e) {
    const timecode = parseFloat(e.getAttribute(TranscriptVideo.attributeStartTime))
    const element = e.getAttribute("data-transcript-video-target") === "chapter" ? this.getTargetElement(this.paragraphTargets, timecode) : this.getTargetElement(this.chapterTargets, timecode)
    element && element.scrollIntoView({ behavior: "smooth", block: "center" })
  }

  highlight(e) {
    if (this.speakerTargets.length === 1) {
      return this.speakerTargets[0].classList.add(HIGHLIGHTED_CLASS)
    }

    // Remove previous highlight
    this.paragraphTargets.forEach((element) => element.classList.contains(HIGHLIGHTED_CLASS) && element.classList.remove(HIGHLIGHTED_CLASS))

    const speaker = this.getSpeaker(e.currentTarget)
    speaker && speaker.classList.add(HIGHLIGHTED_CLASS)
  }

  unhighlight() {
    this.speakerTargets.forEach((element) => element.classList.contains(HIGHLIGHTED_CLASS) && element.classList.remove(HIGHLIGHTED_CLASS))
  }

  connect() {
    this.navigateToContent()
  }
}

class TranscriptSearch extends Controller {
  static values = { query: String, isPageList: Boolean }
  static classParagraph = ".transcript-paragraph__text"
  static classTitle = ".transcript-metadata__title"

  connect() {
    if (this.queryValue) {
      this.markSearch()
    }
  }

  markSearch() {
    const markClass = this.isPageListValue ? TranscriptSearch.classTitle : TranscriptSearch.classParagraph
    const marker = new Mark(markClass)
    const query = this.queryValue.trim()

    marker.mark(query, {
      separateWordSearch: false,
      className: HIGHLIGHTED_CLASS,
    })
  }
}

class TranscriptSearchNavigation extends Controller {
  static targets = ["paragraph", "previousButton", "nextButton"]

  connect() {
    if (isMobile()) return
    this.navPosition = 0
    this.scrollToElement()
  }

  next() {
    if (this.navPosition === this.paragraphTargets.length - 1) {
      return
    }
    this.navPosition++
    this.disableButton("next")
    this.scrollToElement()
  }

  previous() {
    if (this.navPosition === 0) {
      return
    }
    this.navPosition--
    this.disableButton("previous")
    this.scrollToElement()
  }

  // private methods

  disableButton(direction) {
    if (direction === "next" && this.navPosition === this.paragraphTargets.length - 1) {
      this.updateButtonState(this.nextButtonTarget, true)
    }
    if (direction === "previous" && this.navPosition === 0) {
      this.updateButtonState(this.previousButtonTarget, true)
    }
    if (this.navPosition > 0) {
      this.updateButtonState(this.previousButtonTarget, false)
    }
    if (this.navPosition < this.paragraphTargets.length - 1) {
      this.updateButtonState(this.nextButtonTarget, false)
    }
  }

  updateButtonState(button, condition) {
    button.disabled = condition
    if (condition) button.classList.add(DISABLED_CLASS)
    else button.classList.remove(DISABLED_CLASS)
  }

  scrollToElement() {
    if (this.paragraphTargets.length === 0) {
      if (window.scrollY !== 0) {
        window.scrollTo({ behavior: "smooth", top: 0 })
      }
      return
    }
    this.paragraphTargets[this.navPosition].scrollIntoView({ behavior: "smooth", block: "center" })
    this.paragraphTargets.forEach((element) => element.classList.remove(SCROLLING_INTO_CLASS))
    this.paragraphTargets[this.navPosition].classList.add(SCROLLING_INTO_CLASS)
  }
}

export { TranscriptSearch, TranscriptSearchNavigation, TranscriptVideo }
